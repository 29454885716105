import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'

interface MessagesHeaderProps {
  onNewConversation: () => void
}

export const MessagesHeader: React.FC<MessagesHeaderProps> = ({ onNewConversation }) => (
  <Button type="primary" icon={<PlusOutlined />} onClick={onNewConversation}>
    New Conversation
  </Button>
)

import { gql } from '@apollo/client'

export const SESSION = gql`
  query session {
    session {
      sub
      username
    }
  }
`

import { Button, Layout, Menu, MenuProps, Typography } from 'antd'
import React, { ReactNode } from 'react'
import { useAuth } from 'react-oidc-context'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const { Content: AntContent, Header } = Layout
const { Text } = Typography

const CustomHeader = styled(Header)`
  background: #fff;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`

export const Wrapper: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const auth = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  const currentPath = location.pathname.substring(1) || 'home'

  const menuItems: MenuProps['items'] = [
    // {
    //   label: 'Home',
    //   key: 'home',
    //   onClick: () => navigate('/'),
    // },
    // {
    //   label: 'Messages',
    //   key: 'messages',
    //   onClick: () => navigate('/messages'),
    // },
    // {
    //   label: 'Events',
    //   key: 'events',
    //   onClick: () => navigate('/events'),
    // },
  ]

  const handleSignOut = async () => {
    await auth.removeUser()
    navigate('/')
  }

  const buttonSignOut = (
    <Button type="primary" onClick={handleSignOut}>
      Sign out
    </Button>
  )
  const buttonSignIn = (
    <Button type="primary" onClick={() => auth.signinRedirect()}>
      Sign in
    </Button>
  )

  return (
    <Layout>
      <CustomHeader>
        <StyledWrapper>
          <Text strong>FaaS.club</Text>

          {auth.isAuthenticated && (
            <Menu mode="horizontal" defaultSelectedKeys={[currentPath]} items={menuItems} style={{ border: 'none' }} />
          )}
        </StyledWrapper>

        {auth.isAuthenticated ? buttonSignOut : buttonSignIn}
      </CustomHeader>
      <AntContent style={{ padding: '24px 0px 0px 0px' }}>{children}</AntContent>
    </Layout>
  )
}

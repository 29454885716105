import { List } from 'antd'
import React from 'react'
import { Message } from '../../types/messages'
import { MessageListItem } from './MessageListItem'

interface MessageListProps {
  messages: Message[]
  onMessageClick: (id: string) => void
}

export const MessageList: React.FC<MessageListProps> = ({ messages, onMessageClick }) => {
  return (
    <List
      itemLayout="horizontal"
      dataSource={messages}
      renderItem={message => (
        <MessageListItem key={message.id} message={message} onClick={() => onMessageClick(message.id)} />
      )}
    />
  )
}

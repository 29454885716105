import { ApolloClient, ApolloLink, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client'
import { Typography } from 'antd'
import { useAuth } from 'react-oidc-context'

import { setContext } from '@apollo/client/link/context'
import { useEffect } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { Wrapper } from './components/Wrapper'
import { APPSYNC_ENDPOINT } from './config'
import { Dashboard } from './pages/Dashboard'
import { Events } from './pages/Events'
import { Home } from './pages/Home'
import { MessageDetails } from './pages/MessageDetails'
import { Messages } from './pages/Messages'

const { Text } = Typography

function App() {
  const auth = useAuth()
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (auth.isAuthenticated && searchParams.get('code')) {
      navigate('/')
    }
  }, [auth.isAuthenticated])

  if (auth.error) {
    return (
      <Wrapper>
        <Text type="danger">{auth.error.message}</Text>
      </Wrapper>
    )
  }

  if (auth.isLoading || !auth.isAuthenticated) {
    return <Wrapper></Wrapper>
  }

  const link = ApolloLink.from([
    setContext(async (_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${auth.user!.access_token}`,
        },
      }
    }),
    createHttpLink({ uri: APPSYNC_ENDPOINT }),
  ])

  const client = new ApolloClient({ link, cache: new InMemoryCache() })

  return (
    <Wrapper>
      <ApolloProvider client={client}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/messages/:id" element={<MessageDetails />} />
          <Route path="/events" element={<Events />} />
        </Routes>
      </ApolloProvider>
    </Wrapper>
  )
}

export default App

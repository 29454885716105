import { AuthContextProps } from 'react-oidc-context'
import { APPSYNC_EVENTS_ID, APPSYNC_EVENTS_REGION } from '../config'

const HOST_PUBLISH = `https://${APPSYNC_EVENTS_ID}.appsync-api.${APPSYNC_EVENTS_REGION}.amazonaws.com/event`
const HOST_SOCKET = `wss://${APPSYNC_EVENTS_ID}.appsync-realtime-api.${APPSYNC_EVENTS_REGION}.amazonaws.com/event/realtime`
const HOST_AUTH = `${APPSYNC_EVENTS_ID}.appsync-api.${APPSYNC_EVENTS_REGION}.amazonaws.com`

let socket: WebSocket | undefined = undefined

export const createSocket = (auth: AuthContextProps) => {
  const subscribe = (channel: string) => {
    socket!.send(
      JSON.stringify({
        type: 'subscribe',
        id: crypto.randomUUID(),
        channel,
        authorization: {
          host: HOST_AUTH,
          Authorization: auth.user!.access_token,
        },
      }),
    )
  }

  const publish = (channel: string, payload: any) => {
    return fetch(HOST_PUBLISH, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${auth.user!.access_token}`,
      },
      body: JSON.stringify({
        channel,
        events: [JSON.stringify(payload)],
      }),
    })
  }

  if (!socket) {
    const authData = JSON.stringify({
      host: HOST_AUTH,
      Authorization: auth.user!.access_token,
    })

    socket = new WebSocket(HOST_SOCKET, [
      'aws-appsync-event-ws',
      `header-${btoa(authData).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')}`,
    ])

    socket.onopen = () => {
      socket!.send(JSON.stringify({ type: 'connection_init' }))
    }
  }

  return {
    socket,
    subscribe,
    publish,
  }
}

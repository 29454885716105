import { LeftOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Button, Space, Typography } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Conversation } from '../../types/messages'

const { Text } = Typography

const StyledAvatarGroup = styled(Avatar.Group)`
  .ant-avatar {
    border: 2px solid #fff;
    margin-inline-end: -12px !important;
  }
`

const StyledSpace = styled(Space)`
  align-items: center;
`

const StyledText = styled(Text)<{ $isGroup?: boolean }>`
  margin-left: ${props => (props.$isGroup ? '20px' : '0')};
`

interface MessageHeaderProps {
  conversation: Conversation
}

export const MessageHeader: React.FC<MessageHeaderProps> = ({ conversation }) => {
  const navigate = useNavigate()

  return (
    <StyledSpace>
      <Button type="text" icon={<LeftOutlined />} onClick={() => navigate(-1)} />
      {conversation.isGroup ? (
        <StyledAvatarGroup maxCount={2}>
          {conversation.participants.map(participant => (
            <Avatar key={participant.id} icon={!participant.avatar && <UserOutlined />} src={participant.avatar} />
          ))}
        </StyledAvatarGroup>
      ) : (
        <Avatar
          icon={!conversation.participants[0].avatar && <UserOutlined />}
          src={conversation.participants[0].avatar}
        />
      )}
      <StyledText $isGroup={conversation.isGroup} strong>
        {conversation.isGroup
          ? conversation.participants.map(p => p.name).join(', ')
          : conversation.participants[0].name}
      </StyledText>
    </StyledSpace>
  )
}

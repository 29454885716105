import { gql } from '@apollo/client'

export const SYSTEM = gql`
  query system {
    system {
      version
      environment
    }
  }
`

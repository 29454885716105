export const APPSYNC_ENDPOINT = 'https://cifi43hyibgmfot56rvxg3lchm.appsync-api.eu-central-1.amazonaws.com/graphql'

export const APPSYNC_EVENTS_ID = 'ezykzwosvjcmbmdtahdvs4j3de'
export const APPSYNC_EVENTS_REGION = 'eu-central-1'

export const COGNITO_HOST = 'https://auth.faas.club'

export const HOSTNAME_DEPLOYMENT = 'https://faas.club'
export const HOSTNAME_LOCAL = 'http://localhost:3000'

export const AUTH_CONFIG = {
  authority: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_wrW2QUXBi',
  client_id: '6n1hf59di3lb65pr3hpec6p3kk',
  redirect_uri: window.location.href.indexOf('localhost') > -1 ? HOSTNAME_LOCAL : HOSTNAME_DEPLOYMENT,
  response_type: 'code',
  scope: 'aws.cognito.signin.user.admin email openid phone profile',
}

export const BUILD_VERSION = process.env.REACT_APP_BUILD_VERSION || 'local'
export const BUILD_ENVIRONMENT = process.env.REACT_APP_BUILD_ENVIRONMENT || 'development'

import { Layout, Space } from 'antd'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { AuthProvider } from 'react-oidc-context'
import { BrowserRouter } from 'react-router-dom'
import styled from 'styled-components'
import App from './App'
import { AUTH_CONFIG } from './config'
const { Content: AntContent } = Layout

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const StyledContent = styled(AntContent)`
  padding: 24px;
  min-height: 100vh;
`

const StyledSpace = styled(Space)`
  width: 100%;
`

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Layout>
        <StyledContent>
          <StyledSpace direction="vertical" size="large">
            <AuthProvider {...AUTH_CONFIG}>
              <App />
            </AuthProvider>
          </StyledSpace>
        </StyledContent>
      </Layout>
    </BrowserRouter>
  </React.StrictMode>,
)

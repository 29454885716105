import { SendOutlined } from '@ant-design/icons'
import { Button, Card, Input } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { MessageHeader } from '../components/messages/MessageHeader'
import { conversations } from '../data/mockMessages'
import { ChatMessage } from '../types/messages'

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 200px);
`

const MessagesContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const InputContainer = styled.div`
  padding: 16px;
  background: #fff;
  border-top: 1px solid #f0f0f0;
  display: flex;
  gap: 8px;
`

const MessageBubble = styled.div<{ isSender: boolean }>`
  max-width: 70%;
  padding: 12px 16px;
  border-radius: 16px;
  background: ${props => (props.isSender ? '#1677ff' : '#f0f0f0')};
  color: ${props => (props.isSender ? '#fff' : 'inherit')};
  align-self: ${props => (props.isSender ? 'flex-end' : 'flex-start')};
  position: relative;
`

const TimeStamp = styled.div`
  font-size: 12px;
  color: #8c8c8c;
  margin-top: 4px;
  text-align: right;
`

export const MessageDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [message, setMessage] = useState('')
  const [conversation, setConversation] = useState(conversations.find(m => m.id === id))
  const messagesEndRef = useRef<HTMLDivElement>(null)

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    scrollToBottom()
  }, [conversation?.messages])

  const handleSend = () => {
    if (!message.trim()) return

    const newMessage: ChatMessage = {
      id: `m${Date.now()}`,
      senderId: 'currentUser',
      content: message,
      timestamp: new Date().toISOString(),
      status: 'sent',
    }

    setConversation(prev => {
      if (!prev) return prev
      return {
        ...prev,
        messages: [...prev.messages, newMessage],
        lastMessage: message,
        timestamp: new Date().toISOString(),
      }
    })
    setMessage('')
  }

  if (!conversation) return <div>Conversation not found</div>

  return (
    <Card title={<MessageHeader conversation={conversation} />} bordered={false} bodyStyle={{ padding: 0 }}>
      <ChatContainer>
        <MessagesContainer>
          {conversation.messages.map(msg => (
            <MessageBubble key={msg.id} isSender={msg.senderId === 'currentUser'}>
              {msg.content}
              <TimeStamp>
                {new Date(msg.timestamp).toLocaleTimeString([], {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </TimeStamp>
            </MessageBubble>
          ))}
          <div ref={messagesEndRef} />
        </MessagesContainer>
        <InputContainer>
          <Input.TextArea
            value={message}
            onChange={e => setMessage(e.target.value)}
            placeholder="Type a message..."
            autoSize={{ minRows: 1, maxRows: 4 }}
            onPressEnter={e => {
              if (!e.shiftKey) {
                e.preventDefault()
                handleSend()
              }
            }}
          />
          <Button type="primary" icon={<SendOutlined />} onClick={handleSend} disabled={!message.trim()} />
        </InputContainer>
      </ChatContainer>
    </Card>
  )
}

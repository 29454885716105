import { UserOutlined } from '@ant-design/icons'
import { Avatar, Badge, List, Space, Typography } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { Message } from '../../types/messages'

const { Text } = Typography

const MessagePreview = styled(Text)`
  color: #8c8c8c;
  display: block;
`

const TimeStamp = styled(Text)`
  color: #8c8c8c;
  font-size: 12px;
`

const AvatarWrapper = styled.div`
  width: 48px;
  display: flex;
  justify-content: center;
`

const StyledAvatarGroup = styled(Avatar.Group)`
  width: 48px;
  .ant-avatar {
    border: 2px solid #fff;
    margin-inline-end: -12px !important;
  }

  .ant-avatar:last-child {
    margin-inline-end: 0 !important;
  }

  .ant-avatar-group-pop {
    min-width: unset;
    padding-inline: 4px;
  }
`

interface MessageListItemProps {
  message: Message
  onClick: () => void
  showDetails?: boolean
  conversationPreview?: {
    totalMessages: number
    lastActive: string
  }
}

export const MessageListItem: React.FC<MessageListItemProps> = ({
  message,
  onClick,
  showDetails = false,
  conversationPreview,
}) => {
  return (
    <List.Item style={{ cursor: 'pointer' }} onClick={onClick}>
      <List.Item.Meta
        avatar={
          <AvatarWrapper>
            {message.isGroup ? (
              <Badge dot={message.unread}>
                <StyledAvatarGroup max={{ count: 2 }} size="large">
                  {message.participants.map(participant => (
                    <Avatar
                      key={participant.id}
                      icon={!participant.avatar && <UserOutlined />}
                      src={participant.avatar}
                    />
                  ))}
                </StyledAvatarGroup>
              </Badge>
            ) : (
              <Badge dot={message.unread}>
                <Avatar
                  icon={!message.participants[0].avatar && <UserOutlined />}
                  src={message.participants[0].avatar}
                  size="large"
                />
              </Badge>
            )}
          </AvatarWrapper>
        }
        title={
          <Space style={{ width: '100%', justifyContent: 'space-between' }}>
            <Space direction="vertical" size={0}>
              <Text strong={message.unread}>
                {message.isGroup ? message.participants.map(p => p.name).join(', ') : message.participants[0].name}
              </Text>
              {showDetails && conversationPreview && (
                <Text type="secondary" style={{ fontSize: '12px' }}>
                  {conversationPreview.totalMessages} messages · Last active {conversationPreview.lastActive}
                </Text>
              )}
            </Space>
            <TimeStamp>{message.timestamp}</TimeStamp>
          </Space>
        }
        description={<MessagePreview strong={message.unread}>{message.lastMessage}</MessagePreview>}
      />
    </List.Item>
  )
}

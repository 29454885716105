import { Card, Space } from 'antd'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MessageList } from '../components/messages/MessageList'
import { MessagesHeader } from '../components/messages/MessagesHeader'
import { NewConversationModal } from '../components/messages/NewConversationModal'
import { conversations } from '../data/mockMessages'

export const Messages: React.FC = () => {
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const data = { conversations }
  const loading = false
  const error = null

  const handleStartConversation = async (values: { title: string; message: string }) => {
    try {
      console.log('Starting new conversation:', values)
      setIsModalOpen(false)
    } catch (error) {
      console.error('Error handling conversation:', error)
    }
  }

  if (loading) return <Space>Loading conversations...</Space>
  if (error) return <Space>Error loading conversations.</Space>

  return (
    <Space direction="vertical" size="large" style={{ width: '100%' }}>
      <Card title="Messages" bordered={false} extra={<MessagesHeader onNewConversation={() => setIsModalOpen(true)} />}>
        <MessageList messages={data?.conversations || []} onMessageClick={id => navigate(`/messages/${id}`)} />
      </Card>

      <NewConversationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleStartConversation}
      />
    </Space>
  )
}

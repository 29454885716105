import { Conversation } from '../types/messages'

export const conversations: Conversation[] = [
  {
    id: '1',
    participants: [
      {
        id: 'user1',
        name: 'John Smith',
        avatar: 'https://xsgames.co/randomusers/avatar.php?g=male&seed=1',
      },
    ],
    lastMessage: 'Could you review my pull request when you have a moment?',
    timestamp: '2024-03-20T15:30:00Z',
    unread: true,
    isGroup: false,
    messages: [
      {
        id: 'm1',
        senderId: 'user1',
        content: 'Hey, I just pushed some changes to the frontend repo',
        timestamp: '2024-03-20T15:15:00Z',
        status: 'read',
      },
      {
        id: 'm2',
        senderId: 'currentUser',
        content: "Great, I'll take a look at it",
        timestamp: '2024-03-20T15:20:00Z',
        status: 'delivered',
      },
      {
        id: 'm3',
        senderId: 'user1',
        content: 'Could you review my pull request when you have a moment?',
        timestamp: '2024-03-20T15:30:00Z',
        status: 'sent',
      },
      {
        id: 'm3a',
        senderId: 'user1',
        content: 'I mainly updated the authentication flow and added some unit tests',
        timestamp: '2024-03-20T15:31:00Z',
        status: 'sent',
      },
      {
        id: 'm3b',
        senderId: 'currentUser',
        content: "Sure, what's the PR number?",
        timestamp: '2024-03-20T15:33:00Z',
        status: 'delivered',
      },
      {
        id: 'm3c',
        senderId: 'user1',
        content: '#342 - thanks!',
        timestamp: '2024-03-20T15:34:00Z',
        status: 'sent',
      },
    ],
  },
  {
    id: '2',
    participants: [
      {
        id: 'user2',
        name: 'Oliver Frank',
        avatar: 'https://xsgames.co/randomusers/avatar.php?g=pixel&seed=2',
      },
      {
        id: 'user3',
        name: 'Sarah Chen',
        avatar: 'https://xsgames.co/randomusers/avatar.php?g=female&seed=3',
      },
      {
        id: 'user4',
        name: 'Mike Johnson',
        avatar: 'https://xsgames.co/randomusers/avatar.php?g=male&seed=4',
      },
    ],
    lastMessage: 'Updated the component library documentation',
    timestamp: '2024-03-20T14:45:00Z',
    unread: false,
    isGroup: true,
    messages: [
      {
        id: 'm4',
        senderId: 'user2',
        content: 'Updated the component library documentation',
        timestamp: '2024-03-20T14:45:00Z',
        status: 'read',
      },
      {
        id: 'm4a',
        senderId: 'user3',
        content: 'Great work! Could you add some examples for the new form components?',
        timestamp: '2024-03-20T14:50:00Z',
        status: 'read',
      },
      {
        id: 'm4b',
        senderId: 'user4',
        content: "I noticed a few typos in the API reference section, I'll create a PR to fix them",
        timestamp: '2024-03-20T14:52:00Z',
        status: 'read',
      },
      {
        id: 'm4c',
        senderId: 'user2',
        content: "@Sarah - Yes, I'll add those examples now\n@Mike - Thanks for catching those!",
        timestamp: '2024-03-20T14:55:00Z',
        status: 'read',
      },
    ],
  },
  {
    id: '3',
    participants: [
      {
        id: 'user5',
        name: 'Emma Wilson',
        avatar: 'https://xsgames.co/randomusers/avatar.php?g=female&seed=5',
      },
    ],
    lastMessage: 'Thanks for helping with the TypeScript migration!',
    timestamp: '2024-03-20T13:15:00Z',
    unread: false,
    isGroup: false,
    messages: [
      {
        id: 'm5',
        senderId: 'user5',
        content: 'Thanks for helping with the TypeScript migration!',
        timestamp: '2024-03-20T13:15:00Z',
        status: 'read',
      },
    ],
  },
  {
    id: '4',
    participants: [
      {
        id: 'user6',
        name: 'Ulrich Fancis',
        avatar: 'https://xsgames.co/randomusers/avatar.php?g=pixel&seed=6',
      },
      {
        id: 'user7',
        name: 'Alex Kumar',
        avatar: 'https://xsgames.co/randomusers/avatar.php?g=male&seed=7',
      },
      {
        id: 'user8',
        name: 'Lisa Park',
        avatar: 'https://xsgames.co/randomusers/avatar.php?g=female&seed=8',
      },
      {
        id: 'user9',
        name: 'Tom Chen',
        avatar: 'https://xsgames.co/randomusers/avatar.php?g=male&seed=9',
      },
    ],
    lastMessage: 'Deployment to production completed successfully',
    timestamp: '2024-03-20T12:00:00Z',
    unread: true,
    isGroup: true,
    messages: [
      {
        id: 'm6',
        senderId: 'user6',
        content: 'Deployment to production completed successfully',
        timestamp: '2024-03-20T12:00:00Z',
        status: 'read',
      },
    ],
  },
  {
    id: '5',
    participants: [
      {
        id: 'user10',
        name: 'David Miller',
        avatar: 'https://xsgames.co/randomusers/avatar.php?g=male&seed=10',
      },
    ],
    lastMessage: 'Can we schedule a code review session?',
    timestamp: '2024-03-20T10:30:00Z',
    unread: false,
    isGroup: false,
    messages: [
      {
        id: 'm7',
        senderId: 'user10',
        content: 'Can we schedule a code review session?',
        timestamp: '2024-03-20T10:30:00Z',
        status: 'sent',
      },
    ],
  },
]

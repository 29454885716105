import { useQuery } from '@apollo/client'
import { Card, Descriptions, Typography } from 'antd'
import React from 'react'
import { useAuth } from 'react-oidc-context'
import { SESSION } from '../queries/session'

const { Text } = Typography

interface SessionData {
  session: {
    sub: string
    username: string
  }
}

export const Session: React.FC = () => {
  const auth = useAuth()
  const { loading, error, data } = useQuery<SessionData>(SESSION)

  if (loading) return <Text type="secondary">Loading...</Text>
  if (error) return <Text type="danger">Error: {error.message}</Text>

  return (
    <Card title="AppSync GraphQL Session">
      <Descriptions column={1}>
        <Descriptions.Item label="ID">{data?.session.sub}</Descriptions.Item>
        <Descriptions.Item label="Username">{data?.session.username}</Descriptions.Item>
      </Descriptions>
    </Card>
  )
}

import { Space } from 'antd'
import React from 'react'

export const Dashboard: React.FC = () => {
  return (
    <Space direction="vertical" size="large" style={{ width: '100%' }}>
      Empty
    </Space>
  )
}

import React, { useState } from 'react'
import { useAuth } from 'react-oidc-context'
import styled from 'styled-components'
import { createSocket } from '../util/socket'

interface SocketEvent {
  id: string
  date: Date
  type: string
  data: Map<string, any>
}

const EventsContainer = styled.div`
  padding: 20px;
`

const EventsLog = styled.div`
  background: #f5f5f5;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 16px;
  font-family: monospace;
  max-height: 80vh;
  overflow-y: auto;
`

const EventItem = styled.div`
  padding: 8px 0;
  border-bottom: 1px solid #e8e8e8;
  white-space: pre-wrap;
  word-break: break-all;

  &:last-child {
    border-bottom: none;
  }
`

const Timestamp = styled.span`
  color: #722ed1;
`

const EventData = styled.span`
  color: #1677ff;
`

export const Events: React.FC = () => {
  const [events, setEvents] = useState<SocketEvent[]>([])
  const auth = useAuth()
  const { socket, subscribe } = createSocket(auth)

  console.log(auth.user?.profile.sub)
  socket.onmessage = event => {
    const data = JSON.parse(event.data)

    if (data.type === 'connection_ack') {
      subscribe('public/*')
      subscribe(`private/${auth.user!.profile.sub}`)
    }

    if (data.type === 'data') {
      const event = JSON.parse(data.event) as SocketEvent

      setEvents(prev => [...prev, { ...event, date: new Date(), id: crypto.randomUUID() }])
    }
  }

  return (
    <EventsContainer>
      <EventsLog>
        {events.map(item => (
          <EventItem key={item.id}>
            <Timestamp>[{item.date.toISOString()}]</Timestamp> <EventData>{JSON.stringify(item)}</EventData>
          </EventItem>
        ))}
      </EventsLog>
    </EventsContainer>
  )
}

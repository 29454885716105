import { CopyOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { Button, Card, Descriptions, message, Space, Typography } from 'antd'
import React, { useState } from 'react'
import { useAuth } from 'react-oidc-context'
import { Session } from '../components/Session'
import { SYSTEM } from '../queries/system'

const { Text } = Typography

export const Home: React.FC = () => {
  const auth = useAuth()
  const { loading, error, data } = useQuery(SYSTEM)
  const [isSystemCollapsed, setIsSystemCollapsed] = useState(false)

  if (error) return <Text type="danger">Error: {error.message}</Text>

  const truncateToken = (token: string) => {
    if (!token) return ''
    return `${token.substring(0, 20)}...${token.substring(token.length - 20)}`
  }

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text)
      message.success('Access token copied to clipboard')
    } catch (err) {
      message.error('Failed to copy access token')
    }
  }

  const BlockUserInformation = (
    <Card
      title="Token Information"
      bordered={false}
      extra={
        <Space>
          <Text code>{auth.user?.profile.sub}</Text>
          <Button
            icon={<CopyOutlined />}
            size="small"
            onClick={() => copyToClipboard(auth.user?.profile.sub || '')}
            title="Copy user ID"
          />
        </Space>
      }
    >
      <Descriptions column={1}>
        <Descriptions.Item label="Username">{auth.user?.profile['cognito:username'] as string} </Descriptions.Item>
        <Descriptions.Item label="Email">{auth.user?.profile.email}</Descriptions.Item>
        <Descriptions.Item label="Access Token">
          <Space>
            {truncateToken(auth.user?.access_token || '')}
            <Button
              icon={<CopyOutlined />}
              size="small"
              onClick={() => copyToClipboard(auth.user?.access_token || '')}
              title="Copy full access token"
            />
          </Space>
        </Descriptions.Item>
        <Descriptions.Item label="Token Expires">
          {auth.user?.expires_at ? (
            <Text>
              {new Date(auth.user.expires_at * 1000).toLocaleString()} (
              {Math.max(0, Math.round((auth.user.expires_at - Date.now() / 1000) / 60))} minutes remaining)
            </Text>
          ) : (
            <Text type="secondary">Unknown</Text>
          )}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  )

  return (
    <Space direction="vertical" size="large" style={{ width: '100%' }}>
      {BlockUserInformation}

      <Session />
      {/* <Events /> */}

      {/* <Card
        title="System Information"
        extra={<a onClick={() => setIsSystemCollapsed(!isSystemCollapsed)}>{isSystemCollapsed ? 'Hide' : 'Show'}</a>}
      >
        {isSystemCollapsed && (
          <Descriptions column={1}>
            <Descriptions.Item label="Frontend">
              {BUILD_ENVIRONMENT}, {BUILD_VERSION}
            </Descriptions.Item>

            <Descriptions.Item label="Backend">
              {data.system.environment}, {data.system.version}
            </Descriptions.Item>
          </Descriptions>
        )}
      </Card> */}
    </Space>
  )
}

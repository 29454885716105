import { Button, Form, Input, Modal, Select, Space } from 'antd'
import styled from 'styled-components'

interface NewConversationModalProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (values: { title: string; message: string; recipients: string[] }) => Promise<void>
}

const StyledForm = styled(Form)`
  .ant-form-item:last-child {
    margin-bottom: 0;
  }
`

const StyledModal = styled(Modal)``

export const NewConversationModal: React.FC<NewConversationModalProps> = ({ isOpen, onClose, onSubmit }) => {
  const [form] = Form.useForm()
  return (
    <StyledModal
      title="Start New Conversation"
      open={isOpen}
      onCancel={() => {
        form.resetFields()
        onClose()
      }}
      footer={null}
    >
      <StyledForm
        form={form}
        layout="vertical"
        onFinish={values => onSubmit(values as { title: string; message: string; recipients: string[] })}
        autoComplete="off"
      >
        <Form.Item
          name="recipients"
          label="Recipients"
          rules={[{ required: true, message: 'Please enter at least one recipient' }]}
        >
          <Select
            mode="tags"
            placeholder="Enter recipients (press Enter after each)"
            tokenSeparators={[',']}
            style={{ width: '100%' }}
          />
        </Form.Item>

        <Form.Item
          name="title"
          label="Conversation Title"
          rules={[{ required: true, message: 'Please enter a title' }]}
        >
          <Input placeholder="Enter conversation title" />
        </Form.Item>

        <Form.Item
          name="message"
          label="Initial Message"
          rules={[{ required: true, message: 'Please enter your message' }]}
        >
          <Input.TextArea rows={4} placeholder="Type your message here" />
        </Form.Item>

        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit" onClick={() => form.submit()}>
              Start Conversation
            </Button>
            <Button
              onClick={() => {
                form.resetFields()
                onClose()
              }}
            >
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </StyledForm>
    </StyledModal>
  )
}
